<template>
  <div class="project-view">
    <div class="project-view__container">
      <div class="project-view__exhibitions">
        <!-- v-for를 사용하여 전시회 목록을 렌더링합니다. -->
        <div class="project-view__exhibition" v-for="(exhibition, index) in exhibitions" :key="index" :ref="'exhibition-' + index">
          <div class="project-view__details">
            <p class="project-view__details-text">
              <span class="project-view__title-underline">{{ exhibition.title }}</span> <br/>
              <span class="project-view__date">{{ exhibition.date }}</span> <br/>
              <span class="project-view__location">{{ exhibition.location }}</span> <br/>
              <span class="project-view__description">{{ exhibition.description }}</span> <br/>
              <a :href="exhibition.link" target="_blank" v-if="exhibition.link">LINK</a>
            </p>
          </div>
          <div class="project-view__media" v-if="exhibition.media && exhibition.media.length">
            <!-- 이미지와 비디오를 렌더링합니다. -->
            <template v-for="(mediaItem, mediaIndex) in exhibition.media" :key="mediaIndex">
              <img v-if="mediaItem.type === 'image'" 
                   :src="mediaItem.src" 
                   :alt="mediaItem.description" 
                   class="project-view__image">
              <iframe v-if="mediaItem.type === 'video'" 
                      :src="mediaItem.videoUrl" 
                      :title="mediaItem.description" 
                      class="project-view__video"
                      frameborder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen>
              </iframe>
            </template>
          </div>
        </div>
      </div>
      <!-- <div class="project-view__timeline" ref="timeline">
        <div v-for="(year, index) in uniqueYears" :key="index" class="project-view__timeline-year" @click="scrollToYear(year)">
          <div style="min-width: 3.5em;">
            <span class="project-view__timeline-title">{{ year }}</span>
            <span v-if="year === activeYear" class="blinking-dot"></span>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import exhibitions from '@/data/exhibitions'; // 데이터 파일을 불러옵니다.

export default {
  name: 'ProjectView',
  data() {
    return {
      exhibitions, // 데이터를 data 객체에 추가합니다.
      activeYear: null // 현재 클릭된 연도를 저장합니다.
    };
  },
  computed: {
    uniqueYears() {
      // exhibitions 배열에서 중복되지 않은 연도를 추출합니다.
      const years = this.exhibitions.map(exhibition => exhibition.year);
      return [...new Set(years)];
    }
  },
  methods: {
    scrollToExhibition(index) {
      const element = this.$refs['exhibition-' + index][0];
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    },
    scrollToYear(year) {
      this.activeYear = year;
      const index = this.exhibitions.findIndex(exhibition => exhibition.year === year);
      if (index !== -1) {
        this.scrollToExhibition(index);
      }
    }
  }
};
</script>

<style scoped>
.project-view {
  display: flex;
  width: 100%;
}
.project-view__title-wrap {
  width: 100%;
  text-align: center;
  margin-bottom: 1em;
}
.project-view__title {
  font-weight: 700;
}
.project-view__container {
  display: flex;
}
.project-view__exhibitions {
  width: 80%;
  display: flex;
  flex-direction: column;
}
.project-view__exhibition {
  margin-bottom: 2em;
}
.project-view__details {
  margin-bottom: 1em;
}
.project-view__details-text {
  font-size: 13px;
}
.project-view__title-underline {
  font-size: 16px;
  text-decoration: underline;
  text-decoration-color: rgb(196, 26, 34);
  text-decoration-thickness: 0.5px;
}
.project-view__date {
  font-size: 14px;
  color: gray;
}
.project-view__location,
.project-view__description {
  display: block;
}
.project-view__media {
  display: flex;
  flex-wrap: wrap;
}
.project-view__image {
  width: auto;
  height: 250px;
  margin-right: 0.5em;
  margin-bottom: 0.5em;
}
.project-view__video {
  width: 400px;
  height: 250px;
}
.project-view__timeline {
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 0.5em; /* 오른쪽에 고정 */
  top: 5em; /* 원하는 오프셋 값으로 조정 */
}
.project-view__timeline-year {
  cursor: pointer;
  margin-bottom: 0.8em;
  display: flex;
  align-items: center;
}
.project-view__timeline-title {
  font-size: 14px;
  font-weight: bold;
  color: rgb(196, 26, 34);
}
.blinking-dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: rgb(196, 26, 34);
  border-radius: 50%;
  margin-left: 8px; /* 간격을 유지합니다. */
  animation: blink 1s infinite;
}
@keyframes blink {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
@media (max-width: 768px) {
  .project-view__container {
    flex-direction: column;
  }
  .project-view__exhibitions {
    width: 100%;
  }
  .project-view__timeline {
    width: auto;
    padding-left: 0;
    position: fixed;
    right: 0.5em; /* 오른쪽에 고정 */
    top: 0;
    transform: translateY(5em); /* 원하는 만큼 내려서 겹치게 합니다. */
  }
  .project-view__image,
  .project-view__video {
    width: 100%;
    height: auto;
  }
}
</style>
