<template>
  <div class="artist-detail-view">
    <div class="artist-detail-view__work-detail" v-if="workDetails">
      <WorkDetailComp :artistId="artistId" :workId="selectedWorkId" />
    </div>
  </div>
</template>

<script>
import WorkDetailComp from '@/components/WorkDetailComp.vue';
import artistWorkDetail from '@/data/artistWorkDetail';

export default {
  name: 'ArtistDetailView',
  components: {
    WorkDetailComp,
  },
  props: {
    artistId: String,
    workId: String
  },
  data() {
    return {
      selectedWorkId: null,
      workDetails: null,
    };
  },
  watch: {
    '$route.params.artistId': {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) this.fetchArtistDetails();
      },
    },
    '$route.params.workId': {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) this.fetchArtistDetails();
      },
    }
  },
  methods: {
    fetchArtistDetails() {
      this.workDetails = artistWorkDetail[this.artistId] || {};
      this.selectedWorkId = this.workId || Object.keys(this.workDetails)[0];
    },
  }
};
</script>

<style scoped>


</style>
