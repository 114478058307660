<template>
  <div class="artist-text">
    <div v-html="artistText"></div>
  </div>
</template>

<script>
// 예시: 별도의 파일에서 텍스트 데이터를 가져옵니다.
import { artistTexts } from '@/data/artistTexts';

export default {
  props: {
    artistId: {
      type: String,
      required: true
    }
  },
  computed: {
    artistText() {
      return artistTexts[this.artistId] || '텍스트 정보가 없습니다.';
    }
  }
}
</script>

<style scoped>
.artist-text {
  display: flex;
  flex-direction: column;
  width: calc(100vw * 0.6);
  /* 추가 스타일 */
}
@media (max-width: 768px) {
  .artist-text {
    width: 100%;
}

}
</style>
