<template>
  <div class="artist-gallery" @wheel="handleWheelScroll">
    <div class="artist-gallery__container-wrap">
      <div
        ref="scrollContainer"
        class="artist-gallery__thumbnail-wrap"
      >
        <div
          v-for="(media, index) in artistImages"
          :key="index"
          class="artist-gallery__image-item"
        >
          <div class="artist-gallery__img-wrap" @click="openLightbox(index)">
            <!-- 이미지 렌더링 -->
            <img 
              v-if="media.mediaType === 'image'" 
              v-lazy="media.src" 
              :alt="media.description" 
            />
            <!-- Vimeo 영상 렌더링 (iframe 태그 그대로 삽입) -->
            <div v-else-if="media.mediaType === 'externalIframe'" v-html="media.iframeContent"></div>
          </div>
        </div>
      </div>
      <div class="artist-gallery__title-wrap">
        <h5 class="artist-gallery__title">
          {{ artistImages.length > 0 ? artistImages[0].description : 'No Title & Media' }}
        </h5>
      </div>
    </div>
    <easy-lightbox 
      :visible="visible" 
      :imgs="lightboxImages" 
      :index="lightboxIndex" 
      @hide="handleHide" 
      :move-disabled="true"   
      :swipeTolerance="50"
      :loop="true"
    ></easy-lightbox>
  </div>
</template>



<script>
import { ref, computed } from 'vue';
import artistWorkDetail from '@/data/artistWorkDetail';

export default {
  props: {
    artistId: String,
    workId: String,
  },
  setup(props) {
    const visible = ref(false);
    const lightboxIndex = ref(0);

    // Artist Media 데이터를 가져옴
    const artistImages = computed(() => {
      return artistWorkDetail[props.artistId]?.[props.workId] || [];
    });

    const lightboxImages = computed(() => {
      return artistImages.value
        .filter((media) => media.mediaType === 'image') // Lightbox는 이미지만 처리
        .map((image) => image.src);
    });

    const openLightbox = (index) => {
      if (artistImages.value[index]?.mediaType === 'image') {
        lightboxIndex.value = index;
        visible.value = true;
      }
    };

    const handleHide = () => {
      visible.value = false;
    };

    const handleWheelScroll = (event) => {
      event.preventDefault();
      event.stopPropagation();
      const container = event.currentTarget.querySelector('.artist-gallery__thumbnail-wrap');
      container.scrollLeft += event.deltaY + event.deltaX; // deltaY와 deltaX를 함께 사용하여 상하좌우 스크롤 모두 처리
    };

    return {
      visible,
      lightboxIndex,
      artistImages,
      lightboxImages,
      openLightbox,
      handleHide,
      handleWheelScroll,
    };
  },
};
</script>


<style scoped>
.artist-gallery {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.artist-gallery__thumbnail-wrap {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden; /* 상하 스크롤 숨김 */
  padding-bottom: 10px;
  white-space: nowrap; /* 추가: 자식 요소들이 한 줄로 나타나도록 설정 */
}

.artist-gallery__image-item {
  display: inline-block; /* 변경: flex에서 inline-block으로 변경 */
  margin-right: 10px; /* 오른쪽 여백을 10px로 설정 */
}

.artist-gallery__img-wrap img {
  max-height: 700px;
  object-fit: contain;
  cursor: pointer; /* 커서 변경 추가 */
}

.artist-gallery__img-wrap iframe {
  width: 100%; /* 부모 컨테이너 너비에 맞춤 */
  height: 100%; /* 부모 컨테이너 높이에 맞춤 */
  border: none; /* 불필요한 테두리 제거 */
  object-fit: cover; /* 영상 크롭(컨테이너 비율에 맞게 잘림) */
}

@media screen and (max-width: 768px) {
  .artist-gallery__img-wrap img {
    max-height: 500px;
    object-fit: contain;
  }
}
</style>
