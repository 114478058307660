<template>
  <div class="pdf-gallery">
    <div ref="scrollContainer" class="pdf-gallery__wrapper">
      <div class="pdf-gallery__item" v-for="(item, index) in pdfItems" :key="index">
        <div class="pdf-gallery__content">
          <img :src="item.thumbnail" @click="openPdfInNewTab(item.pdfSrc)" class="pdf-gallery__thumbnail">
          <h5 class="pdf-gallery__title">{{ item.title }}</h5>
          <div class="pdf-gallery__details">
            <span>{{ item.dimensions }}, {{ item.pages }} pages</span>
            <span>{{ item.publication }}</span>
            <span>{{ item.writer }}</span>
            <span>{{ item.link }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pdfItems: [
        {
          title: '지금 Jigeum Vol.4',
          dimensions: '297x420mm',
          pages: 14,
          pdfSrc: 'https://acrobat.adobe.com/id/urn:aaid:sc:AP:e36c93a9-3d55-4a12-8cd9-2d79bff66bdd',
          thumbnail: require('@/assets/pdf/4.jpg'),
        },
        {
          title: '지금 Jigeum Vol.3',
          dimensions: '297x420mm',
          pages: 12,
          pdfSrc: 'https://acrobat.adobe.com/id/urn:aaid:sc:AP:a5615491-25b2-4b7f-8eec-b06468047f39',
          thumbnail: require('@/assets/pdf/3.jpg'),
        },
        {
          title: '지금 Jigeum Vol.2',
          dimensions: '297x420mm',
          pages: 16,
          pdfSrc: 'https://acrobat.adobe.com/id/urn:aaid:sc:AP:7105abff-6ae1-478a-af60-b9c5e844262c',
          thumbnail: require('@/assets/pdf/2.jpg'),
        },
        {
          title: '지금 Jigeum Vol.1',
          dimensions: '297x420mm',
          pages: 12,
          pdfSrc: 'https://acrobat.adobe.com/id/urn:aaid:sc:AP:666ca1a4-00f0-4aeb-842c-732dd64adb9b',
          thumbnail: require('@/assets/pdf/1.jpg'),
        },
        {
          title: 'New Generation-Sizak',
          dimensions: '180x240mm',
          publication : 'Publisher: The Museum of Photography Seoul / 한미사진미술관',
          link : 'www.photomuseum.or.kr', 
          writer : 'With Essay by Park, Pyung Jong / 글: 박평종',
          pages: 200,
          pdfSrc: 'https://acrobat.adobe.com/id/urn:aaid:sc:AP:aea6c015-bdac-45cc-9073-4544612303c2',
          thumbnail: require('@/assets/pdf/6.jpg'),
        },
        {
          title: 'Public Art Project, Well to the Moon',
          dimensions: '160x210mm',
          pages: 62,
          pdfSrc: 'https://acrobat.adobe.com/id/urn:aaid:sc:AP:e7ab1c7f-9f19-4c36-b6ed-d0a125081da2',
          thumbnail: require('@/assets/pdf/5.jpg'),
        },
        {
          title: 'PHOTOBOOK LOUNGE PROJECT 01',
          dimensions: '297x420mm',
          pages: 2,
          pdfSrc: 'https://acrobat.adobe.com/id/urn:aaid:sc:AP:e7ab1c7f-9f19-4c36-b6ed-d0a125081da2',
          thumbnail: require('@/assets/pdf/book_1.jpg'),
        },
        {
          title: 'PHOTOBOOK LOUNGE PROJECT 02',
          dimensions: '297x420mm',
          pages: 2,
          pdfSrc: 'https://acrobat.adobe.com/id/urn:aaid:sc:AP:8f6b017f-c528-45b5-9e0f-c4a8a2c6a0f6',
          thumbnail: require('@/assets/pdf/book_2.jpg'),
        },
        {
          title: 'PHOTOBOOK LOUNGE PROJECT 03',
          dimensions: '297x420mm',
          pages: 2,
          pdfSrc: 'https://acrobat.adobe.com/id/urn:aaid:sc:AP:0472c104-499b-4b31-b2c8-4917a49ab0fd',
          thumbnail: require('@/assets/pdf/book_3.jpg'),
        },
        {
          title: 'PHOTOBOOK LOUNGE PROJECT 04',
          dimensions: '297x420mm',
          pages: 2,
          pdfSrc: 'https://acrobat.adobe.com/id/urn:aaid:sc:AP:95708cf4-0aaf-4d7f-b912-744566fbe2ff',
          thumbnail: require('@/assets/pdf/book_4.jpg'),
        },
        // 추가 PDF 항목들...
      ],
      selectedPdf: null // 선택된 PDF의 경로
    };
  },
  methods: {
    openPdfInNewTab(pdfSrc) {
      window.open(pdfSrc, '_blank');
    },
    handleScroll(e) {
      const scrollContainer = this.$refs.scrollContainer;
      if (e.deltaY !== 0 && scrollContainer) {
        scrollContainer.scrollLeft += e.deltaY;
      }
    }
  },
  mounted() {
    const scrollContainer = this.$refs.scrollContainer;
    if (scrollContainer) {
      scrollContainer.addEventListener('wheel', this.handleScroll);
    }
  },
  beforeUnmount() {
    const scrollContainer = this.$refs.scrollContainer;
    if (scrollContainer) {
      scrollContainer.removeEventListener('wheel', this.handleScroll);
    }
  }
}
</script>

<style scoped>
.pdf-gallery {
  overflow-x: auto; /* 가로 스크롤 추가 */
}

.pdf-gallery__wrapper {
  display: flex;
  padding-bottom: 10px; /* 스크롤바와 썸네일 사이의 간격 */
}

.pdf-gallery__item {
  display: inline-block; /* inline-block으로 설정하여 썸네일이 가로로 나열되게 함 */
  margin-right: 0.5em; /* 오른쪽 간격 설정 */
}

.pdf-gallery__thumbnail {
  width: auto;
  height: 500px;
  cursor: pointer; /* 마우스 오버 시 커서 변경 */
}

.pdf-gallery__content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}

.pdf-gallery__title {
  font-weight: 700;
  margin-top: 0.5em;
}

.pdf-gallery__details span {
  display: block;
}

.pdf-gallery__details {
  margin-top: 0.5em;
}


@media (min-width: 320px) and (max-width: 425px) {
  /* 320px - 425px 화면 크기에 적용할 스타일 작성 */
  .pdf-gallery {
    display: flex;
    flex-direction: column;
  }
  .pdf-gallery__title {
    white-space: pre-wrap;
    h5 {
      font-size: 14px;
    }
  }
  .pdf-gallery__wrapper {
    display: grid;
    grid-template-columns: 1fr; /* 1열로 배치 */
    gap: 5px; /* 간격 설정 */
    width: 100%;
  }
  .pdf-gallery__item {
    display: flex;
    flex-direction: column;
    align-items: center; /* 이미지와 텍스트 중앙 정렬 */
    width: 100%; /* 아이템이 그리드 셀의 너비를 채우도록 설정 */
  }
  .pdf-gallery__thumbnail {
    width: 100%; /* 그리드 셀의 너비를 채우도록 설정 */
    max-width: 100%; /* 최대 너비를 100%로 제한 */
    height: auto; /* 높이는 자동으로 조정 */
  }
  .pdf-gallery__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center; /* 텍스트 중앙 정렬 */
    width: 100%; /* 컨텐츠가 그리드 셀의 너비를 채우도록 설정 */
  }
}



</style>
