// 썸네일 세부사항 생성 함수
const createThumbnailDetails = (artist, works) => {
  const basePath = require.context('@/assets/work/', true, /\.(jpg|jpeg|png|gif)$/);
  return works.map(work => {
    const highResSrc = basePath(`./${artist}/${work.series}/${work.file}`);
    const lowResSrc = basePath(`./${artist}/${work.series}/low${work.file.split('.')[0]}.jpg`);
    return {
      id: work.id,
      src: highResSrc,
      lowResSrc: lowResSrc,
      description: work.description
    };
  });
};

// 작가와 작품 정보 배열
const artistsWorks = [
  {
    artist: 'TaejunYun',
    code: 'YTJ',
    works: [
      { id: 'Weight_of_Remorseful', series: '1', file: '1.jpg', description: 'Weight of Remorseful' },
      { id: 'Ascent', series: '2', file: '5.jpg', description: 'Ascent' },
      { id: 'Illusion_Stair', series: '3', file: '5.jpg', description: 'Illusion Stair' },
      { id: 'Middle_Turn', series: '4', file: '9.jpg', description: 'Middle Turn' },
      { id: 'Network', series: '5', file: '29.jpg', description: 'Network' }
    ]
  },
  {
    artist: 'YoungjinYoo',
    code: 'YYJ',
    works: [
      { id: 'Nowhere', series: '1', file: '5.jpg', description: 'NOWHERE' },
      { id: 'In_A_Twinkle', series: '2', file: '1.jpg', description: 'In a Twinkle' },
      { id: 'The_Weathering', series: '3', file: '1.jpg', description: 'The Weathering' }
    ]
  },
  {
    artist: 'YoungdonJung',
    code: 'JYD',
    works: [
      { id: 'Ants', series: '1', file: '3.jpg', description: 'Ants' },
      { id: 'Wondering_Wandering', series: '2', file: '9.jpg', description: 'Wondering Wandering' },
      { id: 'Inner_Color', series: '3', file: '4.jpg', description: 'Inner Color' }
    ]
  },
  {
    artist: 'ChankyuKim',
    code: 'KCK',
    works: [
      { id: 'A_Dull_Complete_Work', series: '1', file: 'thum.jpg', description: 'A Dull Complete Work' },
      { id: 'New_shinshin_Photo_Studio', series: '2', file: 'thum.jpg', description: 'New shinshin Photo Studio' },
      { id: 'Story_Story_and_Story', series: '3', file: 'thum.jpg', description: 'Story, Story and Story' }
    ]
  },
  {
    artist: 'HyungsikKim',
    code: 'KHS',
    works: [
      { id: 'Studio_Practice_1', series: '1', file: '1.jpg', description: 'Studio Practice 1' },
      { id: 'Studio_Practice_2', series: '2', file: '8.jpg', description: 'Studio Practice 2' },
      { id: 'Seascape', series: '3', file: '3.jpg', description: 'Seascape' },
      { id: 'White_Object', series: '4', file: '6.jpg', description: 'White Object' },
      { id: 'Two_Squares_and_One_Circle_and_One_Triangle', series: '5', file: '2.jpg', description: 'Two Squares and One Circle and One Triangle' }
    ]
  },
  {
    artist: 'JaeyeonKim',
    code: 'KJY',
    works: [
      { id: '4810_Days', series: '1', file: '9.jpg', description: '4810_Days' },
      { id: 'Untitled', series: '2', file: '3.jpg', description: 'Untitled' },
      { id: 'A_Portrait', series: '3', file: '1.jpg', description: 'A Portrait' },
      { id: 'Green_Time', series: '4', file: '1.jpg', description: 'Green Time' },
      { id: 'The_tree_I_live', series: '5', file: '1.jpg', description: 'The tree I live' },
      { id: '0g_Drawing', series: '6', file: '4.jpg', description: '0g Drawing' }
    ]
  },
  {
    artist: 'JungjinAhn',
    code: 'AJJ',
    works: [
      { id: 'No_Way_out', series: '2', file: '4.jpg', description: 'No Way out' },
      { id: 'Forest_of_Souvenir', series: '3', file: '1.jpg', description: 'Forest of Souvenir' },
      { id: 'Perspective_of_Viewpoint', series: '1', file: '5.jpg', description: 'Perspective of Viewpoint' }
    ]
  },
  {
    artist: 'JihyunJung',
    code: 'JJH',
    works: [
      { id: 'Demolition_Site', series: '1', file: '1.jpg', description: 'Demolition Site' },
      { id: 'Construction_Site_Dreg', series: '3', file: '1.jpg', description: 'Construction Site Dreg' },
      { id: 'Red_Room', series: '4', file: '6.jpg', description: 'Red Room' },
      { id: 'The_Darkside', series: '5', file: '2.jpg', description: 'The Darkside' }
    ]
  },
  {
    artist: 'TaejoongKim',
    code: 'KTJ',
    works: [
      { id: 'Foresta', series: '1', file: '3.jpg', description: 'Foresta' },
      { id: 'Foresta_reverse', series: '2', file: '6.jpg', description: 'Foresta-reverse' }
    ]
  },
  {
    artist: 'EunjuChoi',
    code: 'CEJ',
    works: [
      { id: 'New_Family_Portrait', series: '1', file: '2.jpg', description: 'New-Family Portrait' },
      { id: 'New_Korean_Family', series: '2', file: '1.jpg', description: 'New Korean Family' }
    ]
  },
  {
    artist: 'ChanminJung',
    code: 'JCM',
    works: [
      { id: 'The_front_image_of_the_wall', series: '1', file: '2.jpg', description: 'The front image of the wall' },
      { id: 'The_volume_of_other_side', series: '2', file: '4.jpg', description: 'The volume of other side' }
    ]
  },
  {
    artist: 'YounghoJung',
    code: 'JYH',
    works: [
      { id: 'Monologue_Protest', series: '1', file: '2.jpg', description: 'Monologue Protest' },
      { id: 'Lightless_Photography', series: '2', file: '1.jpg', description: 'Lightless Photography' },
      { id: 'Unphotographable_Cases', series: '3', file: '1.jpg', description: 'Unphotographable Cases' },
      { id: 'Face_Shopping', series: '4', file: '1.jpg', description: 'Face Shopping' }
    ]
  },
  {
    artist: 'SeungwonJung',
    code: 'JSW',
    works: [
      { id: 'Bark', series: '1', file: '2.jpg', description: 'Bark' },
      { id: 'Memories_Full_of_Forgetting', series: '2', file: '1.jpg', description: 'Memories Full of Forgetting Photography' }
    ]
  },
  {
    artist: 'CristianDoeller',
    code: 'CRD',
    works: [
      { id: 'Replay_pyramid_splash', series: '1', file: '1.jpg', description: 'Replay (pyramid splash)' },
      { id: 'Luminograf_1', series: '2', file: '1.jpg', description: 'Luminograf #1' },
      { id: '730331879_detail', series: '3', file: '1.jpg', description: '730331879 (detail)' }
    ]
  },
  {
    artist: 'HyeonwooLee',
    code: 'LHW',
    works: [
      { id: '37°24\'03.3"N 126°39\'19.0"E', series: '1', file: '1.jpg', description: '37°24\'03.3"N 126°39\'19.0"E' },
      { id: 'Overlayer', series: '2', file: '1.jpg', description: 'Overlayer' },
      { id: 'Soft_echo', series: '3', file: '1.jpg', description: 'Soft echo' }
    ]
  },
  {
    artist: 'YeeunLee',
    code: 'LYE',
    works: [
      { id: 'turn_up_the_room_temperature', series: '1', file: '1.jpg', description: 'turn up the room temperature' },
      { id: 'Mind_Building', series: '2', file: '1.jpg', description: 'Mind Building' },
      { id: 'Space_Building', series: '3', file: '1.jpg', description: 'Space Building' }
    ]
  },
  {
    artist: 'DoyoungKim',
    code: 'KDY',
    works: [
      { id: 'On_the_Ground_Underground', series: '1', file: '1.jpg', description: 'On the Ground Underground' },
      { id: 'Flat_Room', series: '2', file: '1.jpg', description: 'Flat Room' },
      { id: '80g', series: '3', file: '1.jpg', description: '80g' }
    ]
  },
  {
    artist: 'ZoePopp',
    code: 'ZOE',
    works: [
      { id: 'Interspace', series: '1', file: '1.jpg', description: 'Interspace' },
      { id: 'Line2', series: '2', file: '10.jpg', description: 'Line2' },
      { id: 'Structure_of_a_city', series: '3', file: '1.jpg', description: 'Structure of a city' },
    ]
  },  {
    artist: 'SoyoungJang',
    code: 'JSY',
    works: [
      { id: 'Winter_Plumage', series: '1', file: '1.jpg', description: '겨울깃 Winter Plumage' },
      { id: 'Sleep_on_the_Body', series: '2', file: '10.jpg', description: '몸이 드는 잠 Sleep on the Body' },
    ]
  },  {
    artist: 'HyejeongYoo',
    code: 'YHJ',
    works: [
      { id: 'I_Never_Run_Out_of_Bullets', series: '1', file: '1.jpg', description: 'I Never Run Out ofBullets' },
      { id: 'That_s_My_Girl', series: '2', file: '4.jpg', description: 'That is My Girl' },
      { id: 'Presence', series: '3', file: '2.jpg', description: 'Presence' }
    ]
  },
  // ... 작가 추가
];

// 작가 썸네일 객체 생성
const artistThumbnails = artistsWorks.reduce((acc, { artist, code, works }) => {
  acc[artist] = createThumbnailDetails(code, works);
  return acc;
}, {});

export default artistThumbnails;
