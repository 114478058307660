const exhibitions = [
    {
      year: '2023',
      date: 'February – May 2023',
      title: 'Infinite Interpretations: A Multiplicity of Truths',
      location: 'Binghamton University Art Museum, Binghamton, New York, USA',
      description: 'In cooperation with Galleri Image, Aarhus, Denmark',
      link: 'https://www.mutualart.com/Exhibition/Infinite-Interpretations--A-Multiplicity/3360D72191D8D06F',
      media: [
        {
          type: 'image',
          src: require('@/assets/project/photo1-1.jpg'),
          description: 'Gallery View of Infinite Interpretations Exhibition'
        },
        {
          type: 'image',
          src: require('@/assets/project/photo1-2.jpg'),
          description: 'Gallery View of Infinite Interpretations Exhibition'
        },
      ]
    },
    {
      date: 'February – May 20October – November 2023',
      title: 'Spaceless',
      location: 'Swiss Embassy, Seoul, Korea',
      description: 'In cooperation with ECAL/University of Art and Design Lausanne',
      link: 'https://www.youtube.com/watch?v=Ko6kezpVjbg',
      media: [
        {
          type: 'image',
          src: require('@/assets/project/photo2-1.jpg'),
          description: 'Gallery View of Infinite Interpretations Exhibition'
        },
        {
          type: 'image',
          src: require('@/assets/project/photo2-2.jpg'),
          description: 'Gallery View of Infinite Interpretations Exhibition'
        },
        {
          type: 'video',
          videoUrl: 'https://www.youtube.com/embed/Ko6kezpVjbg?si=Y8aaozrvUmbWOTze', // YouTube embed link
        },
      ]
    },
    {
      year: '2022',
      date: 'June – August 2022',
      title: 'Where Are We Now?',
      location: 'Sungkok Art Museum, Seoul, Korea',
      description: 'In cooperation with Galleri Image, Aarhus, Denmark',
      media: [
        {
          type: 'video',
          videoUrl: 'https://www.youtube.com/embed/8NuBq5ZnR4Q', // YouTube embed link
          description: 'Exhibition Video Description'
        },
      ]
    },
    {
      year: '2021',
      date: 'September – November 2021',
      title: 'WhVisible Voices-Young Korean Photography Now!',
      location: 'Laznia Center for Contemporary Art, Gdansk, Poland',
      description: ' ',
      link: 'https://www.laznia.pl/wystawy/visible-voices-young-korean-photography-now-386/',
      media: [
        {
          type: 'image',
          src: require('@/assets/project/photo3-1.jpg'),
          description: 'Gallery View of Infinite Interpretations Exhibition'
        },
        {
          type: 'image',
          src: require('@/assets/project/photo3-2.jpg'),
          description: 'Gallery View of Infinite Interpretations Exhibition'
        },
      ]
    },
    {
      date: 'October 2021',
      title: 'Breathing Walls-Book and Exhibition Project',
      location: 'Swiss Embassy, Seoul, Korea',
      descriptio: ' ',
      media: [
        {
          type: 'image',
          src: require('@/assets/project/photo4-1.jpg'),
          description: 'Gallery View of Infinite Interpretations Exhibition'
        },
        {
          type: 'image',
          src: require('@/assets/project/photo4-2.jpg'),
          description: 'Gallery View of Infinite Interpretations Exhibition'
        },
      ]
    },
    {
      date: 'September 2021',
      title: 'Family, Photography, and Goodbye Photo Album',
      location: 'Artspace Eonju Round, Seoul, Korea',
      descriptio: ' ',
      media: [
        {
          type: 'image',
          src: require('@/assets/project/photo5-1.jpg'),
          description: 'Gallery View of Infinite Interpretations Exhibition'
        },
        {
          type: 'image',
          src: require('@/assets/project/photo5-2.jpg'),
          description: 'Gallery View of Infinite Interpretations Exhibition'
        },
      ]
    },
    {
      year: '2019',
      date: 'November-December 2019',
      title: 'Homagination-An Homage to 180-Year Photography',
      location: 'Space22-Ikseon, Seoul, Korea',
      descriptio: ' ',
      media: [
        {
          type: 'image',
          src: require('@/assets/project/photo6-1.jpg'),
          description: 'Gallery View of Infinite Interpretations Exhibition'
        },
        {
          type: 'image',
          src: require('@/assets/project/photo6-2.jpg'),
          description: 'Gallery View of Infinite Interpretations Exhibition'
        },
      ]
    },
    {
      year: '2018',
      date: 'Feburary - March, 2018',
      title: 'Seoul, Mumbai to New Dehli',
      location: 'Korean Cultural Centre, New Dehli, India',
      descriptio: ' ',
      link: 'https://india.korean-culture.org/en/270/board/123/read/88073'
    },
    {
      date: 'February 2016 - January, 2018',
      title: 'Cabinet Seoul - A Site Specific Exhibition Project (9 exhibitions)',
      location: 'Seunsanga, Seoul, Korea',
      description: 'In cooperation with Galleri Image, Aarhus, Denmark',
      link: 'https://m.blog.naver.com/cabinetseoul?tab=1',
      media: [
        {
          type: 'image',
          src: require('@/assets/project/cabinet-01.jpg'),
          description: 'Gallery View of Infinite Interpretations Exhibition'
        },
        {
          type: 'image',
          src: require('@/assets/project/cabinet-02.jpg'),
          description: 'Gallery View of Infinite Interpretations Exhibition'
        },
      ]
    },
    {
      year: '2017',
      date: 'December, 2017',
      title: '[en]counters - Daily Ration',
      location: 'ArtOxygen, Mumbai, India',
      link: 'https://www.artoxygen.org/'
    },
    {
      date: 'April - June, 2017',
      title: 'Community Art 2017-Annyeonghaseyo',
      location: 'SEMA-Buk Seoul Museum of Art, Seoul. Korea',
      link: 'https://sema.seoul.go.kr/',
      media: [
        {
          type: 'image',
          src: require('@/assets/project/annyeong-01.jpg'),
          description: 'Gallery View of Infinite Interpretations Exhibition'
        },
        {
          type: 'image',
          src: require('@/assets/project/annyeong-02.jpg'),
          description: 'Gallery View of Infinite Interpretations Exhibition'
        },
      ]
    },
    {
      date: 'March - April, 2017',
      title: 'Unknown Knowns',
      location: 'Gallery Skape, Seoul, Korea',
      media: [
        {
          type: 'image',
          src: require('@/assets/project/unknown-01.png'),
          description: 'Gallery View of Infinite Interpretations Exhibition'
        },
        {
          type: 'image',
          src: require('@/assets/project/unknown-02.png'),
          description: 'Gallery View of Infinite Interpretations Exhibition'
        },
      ]
    },
    {
      year: '2016',
      date: 'October - November, 2016',
      title: 'Jirisan Project 2016',
      location: 'Silsangsa Temple, Namwon, Korea',
      link: 'https://blog.naver.com/jirisan-sizak/220841288273',
      media: [
        {
          type: 'image',
          src: require('@/assets/project/jirisan-01.jpg'),
          description: 'Gallery View of Infinite Interpretations Exhibition'
        },
      ]
    },
    {
      date: 'October - November, 2016',
      title: 'Slices - On Time',
      location: 'Villa Romana, Florence, Italy',
      link: 'http://www.villaromana.org/'
    },
    {
      date: 'July - August, 2016',
      title: 'Youngdon Jung - Bank Verse',
      location: 'Photoireland-The Copper House Gallery, Dublin, Ireland',
      link: 'https://photoireland.org/'
    },
    {
      date: 'June - July, 2016',
      title: 'New Generation - SIZAK',
      location: 'Laznia-Center for Contemporary Art, Gdansk, Poland',
      link: 'https://www.laznia.pl/',
      media: [
        {
          type: 'image',
          src: require('@/assets/project/poland-sizak.jpg'),
          description: 'Gallery View of Infinite Interpretations Exhibition'
        },
      ]
    },
    {
      date: 'March, 2016 - April, 2016',
      title: 'Hyungsik Kim - photograph, studio and deception',
      location: 'Gallery Factory, Seoul, Korea',
      link: 'http://factory483.org/'
    },
    {
      year: '2015',
      date: 'June, 2015 - July, 2016',
      title: 'PHOTOBOOK LOUNGE PROJECT, 01~04',
      location: 'In cooperation with the Museum of Photography, Seoul, Korea',
      link: 'www.photomuseum.or.kr',
      media: [
        {
          type: 'image',
          src: require('@/assets/project/museum-1.jpg'),
          description: 'Gallery View of Infinite Interpretations Exhibition'
        },
        {
          type: 'image',
          src: require('@/assets/project/museum-2.jpg'),
          description: 'Gallery View of Infinite Interpretations Exhibition'
        },
      ]
    },
    {
      year: '2015',
      date: 'July, 2015',
      title: 'Photoireland Festival (Winner of Best Portfolio 2015)',
      location: 'South Studios, Dublin, Ireland',
      link: 'www.photoireland.org',
      media: [
        {
          type: 'image',
          src: require('@/assets/project/land-01.jpg'),
          description: 'Gallery View of Infinite Interpretations Exhibition'
        },
        {
          type: 'image',
          src: require('@/assets/project/land-02.jpg'),
          description: 'Gallery View of Infinite Interpretations Exhibition'
        },
      ]
    },
    {
      date: 'May, 2015',
      title: 'Against Photography',
      location: 'Kyungwoo Chun and Project Artists (Hyungsik Kim, Youngdon Jung, Chankyu Kim)',
      description: 'National Museum of Modern & Contemporary Art, Seoul, Korea',
      link: 'https://www.mmca.go.kr/',
      media: [
        {
          type: 'image',
          src: require('@/assets/project/mmca-01.jpg'),
          description: 'Gallery View of Infinite Interpretations Exhibition'
        },
        {
          type: 'image',
          src: require('@/assets/project/mmca-02.jpg'),
          description: 'Gallery View of Infinite Interpretations Exhibition'
        },
        {
          type: 'image',
          src: require('@/assets/project/mmca-03.jpg'),
          description: 'Gallery View of Infinite Interpretations Exhibition'
        },
        {
          type: 'video',
          videoUrl: 'https://www.youtube.com/embed/se1mYmnVCp0?si=Q-idAV_5lUX-FWO1', // YouTube embed link
          description: 'Exhibition Video Description'
        },
      ]
    },
    {
      year: '2014',
      date: 'December, 2014',
      title: 'Special Seminar - Family and Photography',
      location: 'Art Center, Chung-Ang University, Seoul, Korea',
      description: 'Guests: Youngjun Lee, Juseok Park, Sangwoo Park, Pyung Jong Park'
    },
    {
      date: 'May – November, 2014',
      title: 'Solo Exhibitions (Kyungeun Han, Jihyun Jung, Bora Sung, Hyungsik Kim, Youngdon Jung)',
      location: 'Total Museum of Contemporary Art- Project Space The Room, Seoul, Korea',
      link: 'http://totalmuseum.org/'
    },
    {
      date: 'October, 2014',
      title: 'Photobook Week Aarhus',
      location: 'Architecture School Aarhus, Aarhus, Denmark',
      link: 'https://aarch.dk/'
    },
    {
      date: 'September - November, 2014',
      title: 'Changwon Sculpture Biennale 2014',
      location: 'Shin Shin Sajinkwan, Changwon, Korea',
      link: 'https://changwonbiennale.or.kr/cboc/'
    },
    {
      date: 'may – June, 2014',
      title: 'New Generation-CAU Photography',
      location: 'Galleri Image, Aarhus, Denmark',
      link: 'http://galleriimage.dk/',
      media: [
        {
          type: 'image',
          src: require('@/assets/project/new-3.jpg'),
          description: 'Gallery View of Infinite Interpretations Exhibition'
        },
        {
          type: 'image',
          src: require('@/assets/project/new-4.jpg'),
          description: 'Gallery View of Infinite Interpretations Exhibition'
        },
      ]
    },
    {
      date: 'June - August, 2014',
      title: 'Artist in Residence (Youngdon Jung, Taejoong Kim)',
      location: 'LAZNIA-Center for Contemporary Art, Gdansk, Poland',
      link: 'https://www.laznia.pl',
      media: [
        {
          type: 'image',
          src: require('@/assets/project/new-3.jpg'),
          description: 'Gallery View of Infinite Interpretations Exhibition'
        },
        {
          type: 'image',
          src: require('@/assets/project/new-4.jpg'),
          description: 'Gallery View of Infinite Interpretations Exhibition'
        },
      ]
    },
    {
      date: 'April – June, 2014',
      title: 'New Generation-Sizak',
      location: 'The Museum of Photography, Seoul, Korea',
      link: 'https://www.photomuseum.or.kr',
      media: [
        {
          type: 'image',
          src: require('@/assets/project/new-1.jpg'),
          description: 'Gallery View of Infinite Interpretations Exhibition'
        },
        {
          type: 'image',
          src: require('@/assets/project/new-2.jpg'),
          description: 'Gallery View of Infinite Interpretations Exhibition'
        },
      ]
    },
    {
      date: 'January, 2014',
      title: 'The Art Factory Project (공장미술제)',
      location: 'Culture Station Seoul 284, Seoul, Korea',
      description: 'Curated by Jinsuk Suh',
      link: 'https://www.seoul284.org'
    },
    {
      year: '2013',
      date: 'Novermer, 2013',
      title: 'TRIALOG - music, video, performance',
      location: 'Goethe Institut Seoul, Total Museum of Contemporary Art, Seoul',
      link: 'https://www.goethe.de/seoul',
      media: [
        {
          type: 'image',
          src: require('@/assets/project/goethe-1.jpg'),
          description: 'Gallery View of Infinite Interpretations Exhibition'
        },
      ]
    },
    {
      date: 'November - December, 2013',
      title: 'Lianzhou Foto 2013',
      location: 'International Photography Festival, Lianzhou, China',
      link: 'https://www.lianzhoufoto.com',
      media: [
        {
          type: 'image',
          src: require('@/assets/project/lian-1.jpg'),
          description: 'Gallery View of Infinite Interpretations Exhibition'
        },
        {
          type: 'image',
          src: require('@/assets/project/lian-2.jpg'),
          description: 'Gallery View of Infinite Interpretations Exhibition'
        },
      ]
    },
    {
      date: 'July - September, 2013',
      title: 'Alt. + 1000 Festival de photographie, Rossinière, Switzerland',
      link: 'https://www.plus1000.ch',
      media: [
        {
          type: 'image',
          src: require('@/assets/project/swiss-1.jpg'),
          description: 'Gallery View of Infinite Interpretations Exhibition'
        },
        {
          type: 'image',
          src: require('@/assets/project/swiss-2.jpg'),
          description: 'Gallery View of Infinite Interpretations Exhibition'
        },
      ]
    },
    {
      date: 'March - June, 2013',
      title: 'Artist in Residence (Youngjin Yoo, Hyunwoo Jung)',
      location: 'LAZNIA-Center for Contemporary Art, Gdansk, Poland',
      link: 'https://www.laznia.pl'
    },
    {
      year: '2012',
      date: 'October - November, 2012',
      title: '5th European Month of Photography Berlin',
      location: 'IN TRANSIT-The View Of The Other, Berlin, Germany',
      link: 'https://www.emop-berlin.eu'
    },
    {
      date: 'September - October, 2012',
      title: 'Breda Photo-International Photo Festival, Breda, the Netherlands',
      link: 'https://www.bredaphoto.nl',
      media: [
        {
          type: 'image',
          src: require('@/assets/project/breda.jpg'),
          description: 'Gallery View of Infinite Interpretations Exhibition'
        },
      ]
    },
    {
      date: 'November 2012 - October, 2014',
      title: 'Public Art Project-UNUS Space (산학협력 프로젝트)',
      description: 'In cooperation with UNUS Ltd. Seoul, Korea',
      link: 'https://www.theunus.com',
      media: [
        {
          type: 'image',
          src: require('@/assets/project/unus-1.jpg'),
          description: 'Gallery View of Infinite Interpretations Exhibition'
        },
        {
          type: 'image',
          src: require('@/assets/project/unus-2.jpg'),
          description: 'Gallery View of Infinite Interpretations Exhibition'
        },
        {
          type: 'image',
          src: require('@/assets/project/unus-3.jpg'),
          description: 'Gallery View of Infinite Interpretations Exhibition'
        },
      ]
    },
    // ...additional exhibitions
  ];
  
  export default exhibitions;
  