<template>
  <div id="app">
    <div class="menu-container">
      <div class="menu-container__wrap">
        <router-link class="menu-container__link" :to="{ name: 'MainView' }">
          <h3 style="font-weight: 600;" class="menu-container__title menu-container__title--main">{{ name }}</h3>
        </router-link>
        <!-- 아티스트 -->
        <div class="menu-container__link" @click="toggleDropdown">
          <h3 class="menu-container__title">Artists</h3>
          <div class="dropdown-menu" v-if="isDropdownOpen">
            <div
              v-for="(artist, index) in episodes"
              :key="`name-${index}`"
              class="dropdown-menu__item"
              @click="navigateToArtist(artist.router)"
            >
              {{ artist.names }}
            </div>
          </div>
        </div>

        <router-link class="menu-container__link" :to="{ name: 'ProjectView' }">
          <h3 class="menu-container__title">Project</h3>
        </router-link>
        <router-link class="menu-container__link" :to="{ name: 'PublicView' }">
          <h3 class="menu-container__title">Publication</h3>
        </router-link>
        <router-link class="menu-container__link" :to="{ name: 'AboutView' }">
          <h3 class="menu-container__title">About</h3>
        </router-link>
      </div>
    </div>
    <div class="spacer"></div>
    <div class="content">
      <router-view />
    </div>
    <footer class="footer">
      <span class="footer-text">©Sizak and Artists, 2024</span>
    </footer>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';

export default {
  name: 'App',
  setup() {
    const name = ref('SIZAK 시작');
    const episodes = ref([
      { names: 'Jung Youngdon 정영돈', router: 'YoungdonJung' },
      { names: 'Kim Chankyu 김찬규', router: 'ChankyuKim' },
      { names: 'Kim Hyungsik 김형식', router: 'HyungsikKim' },
      { names: 'Kim Jaeyeon 김재연', router: 'JaeyeonKim' },
      { names: 'Ahn Jungjin 안정진', router: 'JungjinAhn' },
      { names: 'Jung Jihyun 정지현', router: 'JihyunJung' },
      { names: 'Kim Taejoon 김태중', router: 'TaejoongKim' },
      { names: 'Yun Taejun 윤태준', router: 'TaejunYun' },
      { names: 'Yoo Youngjin 유영진', router: 'YoungjinYoo' },
      { names: 'Choi Eunjoo 최은주', router: 'EunjuChoi' },
      { names: 'Jung Chanmin 정찬민', router: 'ChanminJung' },
      { names: 'Jung Youngho 정영호', router: 'YounghoJung' },
      { names: 'Jung Seungwon 정승원', router: 'SeungwonJung' },
      { names: 'Cristian Doeller 크리스티안 도엘러', router: 'CristianDoeller' },
      { names: 'Lee Hyeonwoo 이현우', router: 'HyeonwooLee' },
      { names: 'Lee Yeeun 이예은', router: 'YeeunLee' },
      { names: 'Zoe Popp 조이 폽', router: 'ZoePopp' },
      { names: 'Jang Soyoung 장소영', router: 'SoyoungJang' },
      { names: 'Yoo Hyejeong 유혜정', router: 'HyejeongYoo' },

    ]);

    const router = useRouter();
    const isDropdownOpen = ref(false);

    const navigateToArtist = (artistRouter) => {
      isDropdownOpen.value = false; // 드롭다운 메뉴 닫기
      router.push({ name: 'ArtistProfileView', params: { artistId: artistRouter } });
    };

    const toggleDropdown = () => {
      isDropdownOpen.value = !isDropdownOpen.value;
    };

    return {
      name,
      episodes,
      isDropdownOpen,
      navigateToArtist,
      toggleDropdown
    };
  }
};
</script>

<style scoped>

.menu-container {
  width: 100%;
}

.menu-container__wrap {
  display: flex;
  justify-content: space-between;
}

.menu-container__link {
  text-decoration: none;
  position: relative;
  cursor: pointer;
}

.menu-container__title {
  font-size: 16px;
  cursor: pointer;
}

.menu-container__title--main {
  color: #c41a22;
  font-weight: 700;
  text-decoration: underline;
}

.router-link-active .menu-container__title {
  position: relative;
}

.router-link-active .menu-container__title::after {
  content: '';
  position: absolute;
  bottom: -5px; /* 밑줄의 위치 조정 */
  left: 0;
  width: 250px;
  height: 1px;
}

.spacer {
  margin-top: 0.8em;
  margin-bottom: 0.8em;
}

.content {
  flex: 1;
  overflow-y: auto; /* 콘텐츠가 푸터 위로 스크롤되도록 */
}

footer {
  text-align: right;
  padding: 0em 0;
  width: 100%;
}

.footer-text {
  font-size: 12px;
  color: gray;
}

.dropdown-menu {
  display: none;
  position: absolute;
  background-color: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  width: calc(100vw * 0.6);
  padding: 0.8em;
}

.menu-container__link:hover .dropdown-menu {
  display: block;
}

.dropdown-menu__item {
  padding: 8px 15px;
  cursor: pointer;
  font-size: 14px;
}

.dropdown-menu__item:hover {
  background-color: #f1f1f1;
}

@media all and (min-width: 320px) and (max-width: 768px) {
  .menu-container__title {
    font-size: 14px;
  }

  .menu-container__wrap {
    display: flex;
    justify-content: space-between;
  }
}
</style>
