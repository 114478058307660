<template>
  <div class="artist-profile">
    <div class="artist-profile__menu-wrap">
      <div class="artist-profile__menu-title" style="width: 90%;"> 
        <h3><strong>{{ artistId }}</strong></h3> 
      </div>
      <div class="artist-profile__info-wrap">
        <div :class="['artist-profile__info', { 'artist-profile__info--active': currentSection === null }]" @click="setCurrentSection(null)">
          <h3 class="artist-profile__info-text">Works</h3>
        </div>
        <div :class="['artist-profile__info', { 'artist-profile__info--active': currentSection === 'cv' }]" @click="setCurrentSection('cv')">
          <h3 class="artist-profile__info-text">CV</h3>
        </div>
        <div :class="['artist-profile__info', { 'artist-profile__info--active': currentSection === 'text' }]" @click="setCurrentSection('text')">
          <h3 class="artist-profile__info-text">Text</h3>
        </div>
      </div>
    </div>

    <div class="artist-profile__contents-wrap">
      <div v-if="currentSection === 'cv'" class="artist-profile__cv">
        <ArtistCV :artistId="artistId" />
      </div>
      <div v-if="currentSection === 'text'" class="artist-profile__text">
        <ArtistText :artistId="artistId" />
      </div>
      <WorkDetailComp v-if="selectedWorkId" :artistId="artistId" :workId="selectedWorkId" />
      <div v-if="!selectedWorkId && !currentSection" class="artist-profile__img-wrap">
        <ThumbnailComp :thumbnails="currentThumbnails" :artistId="artistId" @workSelected="showWorkDetail" />
      </div>
    </div>
  </div>
</template>

<script>
import ThumbnailComp from '@/components/ThumbnailComp';
import artistThumbnails from '@/data/artistThumbnails';
import ArtistCV from '@/components/ArtistCV';
import ArtistText from '@/components/ArtistText';
import WorkDetailComp from '@/components/WorkDetailComp.vue';
import artistData from '@/data/artistData.json'; // artistData.json 파일 불러오기

export default {
  components: {
    ThumbnailComp,
    ArtistCV,
    ArtistText,
    WorkDetailComp
  },
  props: {
    artistId: String
  },
  data() {
    return {
      currentSection: null,
      artistThumbnails: artistThumbnails,
      selectedWorkId: null,
      artistInfo: null  // 작가 정보 저장할 변수 추가
    };
  },
  watch: {
    '$route'(to) {
      if (to.name === 'ArtistProfileView' && !to.params.workId) {
        this.setCurrentSection(null);
        this.selectedWorkId = null;
      } else if (to.params.workId) {
        this.showWorkDetail(to.params.workId);
      }
    }
  },
  mounted() {
    this.artistInfo = artistData[this.artistId]; // artistData에서 현재 작가 정보 가져오기
    this.addSchemaMarkup();
  },
  methods: {
    setCurrentSection(section) {
      this.currentSection = section;
      this.selectedWorkId = null; 
    },
    showWorkDetail(workId) {
      this.selectedWorkId = workId;
    },
    addSchemaMarkup() {
      if (!this.artistInfo) return; // artistInfo가 없으면 실행 안 함

      const schemaScript = document.createElement('script');
      schemaScript.type = 'application/ld+json';
      schemaScript.text = JSON.stringify({
        "@context": "https://schema.org",
        "@type": "Person",
        "name": this.artistInfo.name,  // artistData에서 이름 가져오기
        "jobTitle": this.artistInfo.jobTitle,
        "worksFor": {
          "@type": "Organization",
          "name": this.artistInfo.worksFor.name
        },
        "url": this.artistInfo.url,
        "description": this.artistInfo.description, // artistData에서 설명 가져오기
      });
      document.head.appendChild(schemaScript);
    }
  },
  computed: {
    currentThumbnails() {
      return this.artistThumbnails[this.artistId] || '이미지가 없습니다.';
    }
  }
};

</script>


<style scoped>
.artist-profile {
  width: 100%;
}

.artist-profile__menu-wrap {
  display: flex;
  margin-bottom: 1.2em;
  justify-content: flex-start;
  width: 100%;
}

.artist-profile__menu-title {
  width: 90%;
}

.artist-profile__info-wrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.artist-profile__info {
  flex: 1 1 auto;
  position: relative;
}

.artist-profile__info--active::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #c41a22;
}

.artist-profile__info:hover {
  cursor: pointer;
  transition: transform 0.3s ease;
}

.artist-profile__cv, .artist-profile__text {
  display: flex;
  flex-direction: column;
}

.artist-profile__contents-wrap {
  width: 100%;
}


.artist-profile__info-text {
  text-align: center;
}
</style>
