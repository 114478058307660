<template>
  <div class="thumbnail-container">
    <div class="thumbnail-container__wrap" v-for="(thumbnail, index) in thumbnails" :key="index" @click="selectWork(thumbnail.id)">
      <!-- router-link를 사용하여 ArtistDetailView로 이동 -->
      <router-link :to="{ name: 'ArtistDetailView', params: { artistId, workId: thumbnail.id } }">
        <div class="thumbnail-container__image">
          <img :src="thumbnail.lowResSrc" :data-src="thumbnail.src" :alt="thumbnail.description" @load="loadHighResImage">
          <div class="thumbnail-container__description">{{ thumbnail.description }}</div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ThumbnailComp',
  props: {
    artistId: String,
    thumbnails: Array
  },
  methods: {
    selectWork(workId) {
      this.$emit('workSelected', workId); // Emit an event with the workId
    },
    loadHighResImage(event) {
      const img = event.target;
      const highResSrc = img.getAttribute('data-src');
      if (highResSrc) {
        const tempImg = new Image();
        tempImg.src = highResSrc;
        tempImg.onload = () => {
          img.src = highResSrc;
        };
      }
    }
  }
};
</script>

<style scoped>
.thumbnail-container {
  display: flex;
  flex-direction: row; /* 가로로 일렬로 표시 */
  flex-wrap: wrap; /* 화면이 줄어들 때 다음 줄로 넘어가도록 설정 */
  justify-content: flex-start; /* 왼쪽 정렬 */
}

.thumbnail-container__wrap {
  position: relative;
  margin-right: 10px;
  margin-bottom: 10px;
}

.thumbnail-container__wrap img {
  width: 250px; /* 가로 크기 설정 */
  height: 250px; /* 세로 크기 설정 */
  object-fit: cover; /* 이미지 비율 유지 */
  transition: transform 0.2s ease;
}

.thumbnail-container__image {
  display: flex;
}

.thumbnail-container__description {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  padding: 1em;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.2s, opacity 0.2s ease;
  text-align: center;
  border-radius: 10px;
}

.thumbnail-container__wrap:hover .thumbnail-container__description {
  visibility: visible;
  opacity: 1;
}

/* 타블렛 크기의 화면에서 적용될 스타일 */
@media (max-width: 768px) {
  .thumbnail-container {
    flex-direction: column; /* 세로로 정렬 */
  }
  
  .thumbnail-container__wrap img {
    width: 100%; /* 화면 폭에 맞게 가로 크기 조정 */
    height: auto; /* 가로 크기에 맞게 높이 자동 조정 */
    max-width: 100vw;
    margin-right: 0px; /* 오른쪽 간격 설정 */
  }

  .thumbnail-container__wrap {
    width: 100%;
    object-fit: cover;
  }
}
</style>
