// HTTP를 HTTPS로 리다이렉션 (프로덕션 환경에서만 실행)
if (process.env.NODE_ENV === 'production' && window.location.protocol === 'http:') {
  window.location.href = `https://${window.location.hostname}${window.location.pathname}${window.location.search}`;
}

import { createApp } from 'vue'; 
import App from './App.vue';
import router from './router';
import './assets/global.css';
import VueLazyLoad from 'vue-lazyload';
import EasyLightbox from 'vue-easy-lightbox';
import VueGtag from 'vue-gtag-next';

// 라우터의 페이지 제목 및 페이지뷰 추적 설정
router.afterEach((to) => {
  // 페이지 제목 설정
  if (to.meta && to.meta.title) {
    document.title = to.meta.title;
  } else {
    document.title = '시작 SIZAK'; // 기본 제목 설정
  }

  // vue-gtag-next를 사용한 페이지뷰 수동 트래킹
  if (typeof window.gtag === 'function') {
    window.gtag('event', 'page_view', {
      page_path: to.path,
      page_title: document.title,
    });
  }
});

// Vue 애플리케이션 생성 및 플러그인 등록
const app = createApp(App);

// VueLazyLoad 설정
app.use(VueLazyLoad);

// VueEasyLightbox 등록
app.component('EasyLightbox', EasyLightbox);

// vue-gtag-next 설정 (Google Analytics)
app.use(VueGtag, {
  property: {
    id: process.env.VUE_APP_GA_MEASUREMENT_ID // 환경 변수에서 Google Analytics ID 가져오기
  },
  isEnabled: process.env.NODE_ENV === 'production', // 프로덕션 환경에서만 활성화
}, router); // 라우터 전달하여 페이지뷰 자동 추적 활성화

// 라우터 사용
app.use(router);

// 애플리케이션 마운트
app.mount('#app');