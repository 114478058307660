<template>
  <div class="artist-cv">
    <div v-if="artistWebsite">
      <a :href="artistWebsite" target="_blank">{{ displayWebsite }}</a>
    </div>
    <br/>
    <div v-html="artistCVContent"></div>
  </div>
</template>

<script>
// 예시: 별도의 파일에서 CV 데이터를 가져옵니다.
import { artistCVs } from '@/data/artistCVs';

export default {
  props: {
    artistId: {
      type: String,
      required: true
    }
  },
  computed: {
    artistCVContent() {
      const artistCV = artistCVs[this.artistId];
      return artistCV ? artistCV.content : 'CV 정보가 없습니다.';
    },
    artistWebsite() {
      const artistCV = artistCVs[this.artistId];
      return artistCV ? `https://${artistCV.website.replace(/^https?:\/\//, '')}` : '';
    },
    displayWebsite() {
      const artistCV = artistCVs[this.artistId];
      return artistCV ? artistCV.website.replace(/^https?:\/\//, '') : '';
    }
  }
}
</script>

<style scoped>
::v-deep .artist-cv p {
  color: red;
}

.artist-cv {
  display: flex;
  flex-direction: column;
  width: calc(100vw * 0.7);
}
@media (max-width: 768px) {
  .artist-cv {
    width: 100%;
}
}
</style>
